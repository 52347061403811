<template>
  <Container :title="$t('primaryMarketPage.primaryMarket')" class="primary-market">
    <v-card elevation="2">
      <v-card-text class="pa-0 pa-sm-1">
        <v-container>
          <v-row>
            <v-col cols="12" class="px-3 px-sm-2">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-row>
                  <v-col cols="12" md="6">
                    <RangeSelectorSlider v-model="investor_interest_range" min="1" max="20" step="0.1" :tooltip="$t('primaryMarketPage.investorInterestPercentDescription')" :title="$t('primaryMarketPage.investorInterestPercent')"/>
                  </v-col>
                  <v-col cols="12" md="6">
                    <RangeSelectorSlider v-model="period_range" min="0" max="120" :tooltip="$t('primaryMarketPage.remainingLoanTermDescription')" :title="$t('primaryMarketPage.remainingLoanTerm')"/>
                  </v-col>
                </v-row>
                <v-container class="buttonDividerContainer" :class="{ 'my-4': showAdvancedSettings }">
                  <v-row align="center">
                    <v-divider/>
                    <v-btn
                      class="ma-2"
                      color="primary lighten-3"
                      outlined
                      @click="showAdvancedSettings = !showAdvancedSettings"
                    >
                      <v-icon v-if="showAdvancedSettings" left>mdi-chevron-up</v-icon>
                      <v-icon v-else left>mdi-chevron-down</v-icon>
                      {{ $t('primaryMarketPage.additionalOptions') }}
                    </v-btn><v-divider/>
                  </v-row>
                </v-container>
                <v-expand-transition>
                  <section v-show="showAdvancedSettings">
                    <v-row>
                      <v-col cols="12" md="6" class="pb-0">
                        <BaseMultiselect
                          v-model="filters.country"
                          filled
                          :items="countries.map(c => c.code)"
                          :label="$t('primaryMarketPage.loanIssueCountry')"
                          :loading="loadingFilterHints"
                          multi-select-data-type="country"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="pb-0 pt-0 pt-md-3">
                        <BaseMultiselect
                          v-model="filters.loan_type"
                          filled
                          :items="loanTypes"
                          item-text="name"
                          item-value="code"
                          :label="$t('primaryMarketPage.loanTypePurpose')"
                          :loading="loadingFilterHints"
                          multi-select-data-type="loanType"
                        />
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <BaseMultiselect
                          v-model="filters.loan_originator__common_name"
                          filled
                          :items="loanOriginators"
                          :label="$t('primaryMarketPage.loanOriginator')"
                          :loading="loadingFilterHints"
                        />
                      </v-col>
                      <v-col cols="12" md="6" class="py-0">
                        <v-row>
                          <v-col cols="12" md="6" class="pb-0">
                            <datepicker
                              v-model="filters.value_date__gte"
                              :label="$t('primaryMarketPage.loanIssueDateFrom')"
                            />
                          </v-col>
                          <v-col cols="12" md="6" class="pt-0 pt-md-3">
                            <datepicker
                              v-model="filters.value_date__lte"
                              :label="$t('primaryMarketPage.loanIssueDateTo')"
                            />
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="pt-0 pb-7 pb-md-4">
                        <RangeSelectorSlider v-model="aprc_range" min="1" max="3000" step="1" :tooltip="$t('primaryMarketPage.borrowerAprcPercentDescription')" :title="$t('primaryMarketPage.borrowerAprcPercent')"/>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-4 py-md-0">
                        <RangeSelectorSlider v-model="borrower_interest_range" min="1" max="1000" step="1" :tooltip="$t('primaryMarketPage.borrowerInterestRatePercentDescription')" :title="$t('primaryMarketPage.borrowerInterestRatePercent')"/>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col cols="12" md="6" class="py-0">
                        <v-select
                          v-model="filters.schedule_extendable"
                          filled
                          :items="[{'text': $t('primaryMarketPage.yes'), 'value': true}, {'text': $t('primaryMarketPage.no'), 'value': false}, ]"
                          :label="$t('loanDetails.isScheduleExtendable')"
                          clearable
                        >
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" color="primary" dark v-on="on">
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span v-text="$t('loanDetails.scheduleExtensionDescription')"/>
                          </v-tooltip>
                        </v-select>
                      </v-col>
                      <v-col cols="12" md="6" class="pt-0 pb-0">
                        <v-select
                          v-model="filters.buyback_guarantee"
                          filled
                          :items="[{'text': $t('primaryMarketPage.yes'), 'value': true}, {'text': $t('primaryMarketPage.no'), 'value': false}, ]"
                          :label="$t('primaryMarketPage.buybackObligation')"
                          clearable
                        >
                          <v-tooltip slot="append-outer" bottom max-width="400">
                            <template #activator="{ on }">
                              <v-icon slot="activator" color="primary"
                                      dark v-on="on"
                              >
                                mdi-information-outline
                              </v-icon>
                            </template>
                            <span v-text="$t('primaryMarketPage.buybackObligationDescription')"/>
                          </v-tooltip>
                        </v-select>
                      </v-col>
                    </v-row>
                    <v-row class="mt-0 mb-0">
                      <v-col cols="6" class="pt-0">
                        <div class="d-flex align-center justify-space-between">
                          <div>
                            <span v-text="$t('primaryMarketPage.showUnavailableToInvest')"/>
                          </div>
                          <div class="py-0 d-flex">
                            <div class="switch-row pr-2">
                              <v-switch v-model="filters.showUnavailable"/>
                            </div>
                            <v-tooltip slot="append-outer" bottom max-width="400">
                              <template #activator="{ on }">
                                <v-icon slot="activator" color="primary" dark v-on="on">
                                  mdi-information-outline
                                </v-icon>
                              </template>
                              <span v-text="$t('primaryMarketPage.showUnavailableToInvestDescription')"/>
                            </v-tooltip>
                          </div>
                        </div>
                      </v-col>
                    </v-row>
                  </section>
                </v-expand-transition>
                <v-row dense>
                  <v-col cols="12">
                    <v-btn ref="filterBtn" large dark @click="submit()" v-text="$t('common.filter')"/>
                    <v-btn large text @click="clear()" v-text="$t('common.clear')"/>
                  </v-col>
                </v-row>
              </v-form>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
    <v-card class="mt-6">
      <v-card-text>
        <LoanList ref="loanList" :items="items" :loading="loading" :count="count" :page.sync="page" :size.sync="size" @investment="getItems"/>
      </v-card-text>
    </v-card>
  </Container>
</template>

<script>

import Vue from 'vue'
import LoanList from "../components/LoanList.vue"
import RangeSelectorSlider from "../../common/components/RangeSelectorSlider"

export default Vue.extend({
  name: "PrimaryMarket",
  components: {
    RangeSelectorSlider,
    LoanList,
  },
  data() {
    return {
      valid: true,
      investor_interest_range: [1, 20],
      period_range: [0, 120],
      aprc_range: [1, 3000],
      borrower_interest_range: [1, 1000],
      filters: {
        showUnavailable: true,
      },
      filterHints: { country: [], loan_type: [], loan_originator: [] },
      loading: false,
      loadingFilterHints: false,
      page: 1,
      size: 10,
      count: 0,
      items: [],
      showAdvancedSettings: false,
      abortController: undefined,
    }
  },
  computed: {
    investmentAccountId() {
      return this.$store.state.account.id
    },
    countries() {
      return this.filterHints.country.map(code => {
        return { code, name: this.$t(`countries.${code}`) }
      })
    },
    loanTypes() {
      return this.filterHints.loan_type.map(code => {
        return { code, name: this.$t(`loanDetails.loanTypes.${code}`) }
      })
    },
    statuses() {
      return [
        { value: 'current',   text: this.$t('primaryMarketPage.current') },
        { value: 'late',      text: this.$t('primaryMarketPage.late')    },
        { value: 'defaulted', text: this.$t('primaryMarketPage.default') },
      ]
    },
    loanOriginators() {
      return this.filterHints.loan_originator.map(lo => lo.common_name)
    },
  },
  watch: {
    page() { this.getItems(); this.$vuetify.goTo(this.$refs.loanList) },
    size() { this.getItems() },
    investor_interest_range(value) {
      this.$set(this.filters, 'investor_interest__gte', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'investor_interest__lte', (value[1] / 100).toFixed(4))
    },
    aprc_range(value) {
      this.$set(this.filters, 'aprc__gte', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'aprc__lte', (value[1] / 100).toFixed(4))
    },
    borrower_interest_range(value) {
      this.$set(this.filters, 'borrower_interest__gte', (value[0] / 100).toFixed(4))
      this.$set(this.filters, 'borrower_interest__lte', (value[1] / 100).toFixed(4))
    },
    period_range(value) {
      if (value.length) {
        this.$set(this.filters, 'maturity_date__gte', value[0])
        this.$set(this.filters, 'maturity_date__lte', value[1])
      }
    },
    filters: {
      deep: true,
      handler() {
        if (this.page !== 1) {
          this.page = 1
        } else {
          this.getItems()
        }
      },
    },
    investmentAccountId() { !this.filters.showUnavailable ? this.page = 1 : this.getItems() },
  },
  mounted() {
    this.getFilterHints()
    this.getItems()
  },
  methods: {
    getFilterHints() {
      this.loadingFilterHints = true
      this.$http
        .get(`/loans/filter_hints/`)
        .then((result) => {
          this.filterHints = result.data
          this.loadingFilterHints = false
        })
        .catch(() => {
          this.loadingFilterHints = false
        })
    },
    clear() {
      this.$set(this.investor_interest_range, 0, '1')
      this.$set(this.investor_interest_range, 1, '20')
      this.$set(this.period_range, 0, '0')
      this.$set(this.period_range, 1, '120')
      this.$set(this.aprc_range, 0, '1')
      this.$set(this.aprc_range, 1, '3000')
      this.$set(this.borrower_interest_range, 0, '1')
      this.$set(this.borrower_interest_range, 1, '1000')
      this.filters = { showUnavailable: true }
    },
    submit() {
      // XXX Although this works without $nextTick with usual mouse click, in our e2e tests Cypress
      //  sends click and release events at the same time, and RangeSelectorSlider's @blur has no
      //  chance to fire beforehand. Theoretically user can encounter this behaviour as well, if
      //  click happens too fast, for example if using some accessibility features. That's why
      //  decision is to fix button, instead of test.
      this.$nextTick(() => {
        this.$nextTick(() => {
          if (this.$refs.form.validate()) {
            this.page = 1
            this.getItems()
          }
        })
      })
    },
    formatFilterDate(date) {
      const month = new String(date.getMonth() + 1)
      const day = new String(date.getDate())
      return `${date.getFullYear()}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`
    },
    getItems() {
      const filters = { ...this.filters }
      // When converting querry parameters to payload parameters, backend accepts values as
      // stringified arrays. To keep payload cleaner, the missing parameters are set to null,
      // whitch would otherways be sent as `undefined`. Any remaining unformatted fields are then
      // deleted for the sake of cleanliness
      filters.loan_originator = filters.loan_originator__common_name?.toString() || null
      filters.loan_type = filters.loan_type?.toString() || null
      filters.country = filters.country?.toString() || null
      delete filters.loan_originator__common_name
      delete filters.showUnavailable // Deleting from local copy only
      this.loading = true
      if (this.abortController !== undefined) { this.abortController.abort() }
      this.abortController = new AbortController()
      this.$http.get('/loans/', {
        signal: this.abortController.signal,
        params: {
          ...filters,
          page: this.page,
          size: this.size,
          // XXX: due to naming of switch being positive and backend variable being negative, we negate the value here
          hide_with_manual_investments: !this.filters.showUnavailable || null,
          hide_fully_invested: !this.filters.showUnavailable || null,
          investment_account: this.investmentAccountId,
        },
      }).then((loans) => {
        this.items = loans.data.results
        this.count = loans.data.count
        this.loading = false
      }).catch((e) => {
        if (e.code != "ERR_CANCELED") {
          // Catch also gets called on cancel, but we don't
          // want to stop the loading animation since we just
          // replaced previous request with a new one
          this.loading = false
        }
      })
    },
  },
})
</script>

<style scoped>
.buttonDividerContainer {
  transition: margin 0.3s;
}
</style>
