<template>
  <v-range-slider
    v-model="range"
    :hint="title"
    persistent-hint
    track-color="grey lighten-1"
    class="align-center"
    :max="max"
    :min="min"
    :step="step"
    :initial-range="initialRange"
    @input="setSliderValues"
    @end="$emit('input', range)"
  >
    <template #prepend>
      <v-text-field
        v-model="rangeFields[0]"
        filled
        class="mt-0 pt-0"
        hide-details
        single-line
        type="number"
        style="width: 4rem"
        @blur="updateRanges('min')"
      />
    </template>
    <template #append>
      <v-text-field
        v-model="rangeFields[1]"
        class="mt-0 pt-0"
        filled
        hide-details
        single-line
        type="number"
        style="width: 6rem"
        @blur="updateRanges('max')"
      >
        <v-tooltip slot="append-outer" bottom max-width="400">
          <template #activator="{ on }">
            <v-icon slot="activator" color="primary" dark v-on="on">
              mdi-information-outline
            </v-icon>
          </template>
          <span v-text="tooltip"/>
        </v-tooltip>
      </v-text-field>
    </template>
  </v-range-slider>
</template>
<script>
import Vue from 'vue'

export default Vue.extend({
  name: "RangeSelectorSlider",
  props: {
    title:   {type: String, default: ""},
    tooltip: {type: String, default: ""},
    min:     {type: String, required: true},
    max:     {type: String, required: true},
    step:    {type: String, default: "1"},
    initialRange: {type: Array,  required: false, default: () => []},
  },
  data() {
    return {
      range: null,
      rangeFields: null,
    }
  },
  watch: {
    initialRange: {
      immediate: true,
      handler(value) {
        if (value.length === 0) {
          value = [this.min, this.max]
        }
        this.range = [...value]
        this.rangeFields = [...this.range]
      },
    },
  },
  methods: {
    updateRanges(type) {
      if (type === 'min' && this.rangeFields[1] < this.rangeFields[0]) {
        this.rangeFields[1] = this.rangeFields[0]
      }
      if (type === 'max' && this.rangeFields[1] < this.rangeFields[0]) {
        this.rangeFields[0] = this.rangeFields[1]
      }
      this.rangeFields[0] = this.clamp(this.rangeFields[0], this.min, this.max)
      this.rangeFields[1] = this.clamp(this.rangeFields[1], this.min, this.max)
      this.range = [...this.rangeFields]
      this.$emit('input', this.range)
    },
    setSliderValues() {
      this.rangeFields = [...this.range]
    },
    /**
     * Returns a number whose value is limited to the given range.
     *
     * @param {Number} num The number that needs to be clamped
     * @param {Number} min The lower boundary of the output range
     * @param {Number} max The upper boundary of the output range
     * @returns {Number} A number in the range [min, max]
     */
    clamp(num, min, max) {
      return Math.min(Math.max(num, min), max)
    },
  },
})
</script>
<style>
  .v-input__prepend-outer, .v-input__append-outer {
    margin: 0;
  }
</style>
