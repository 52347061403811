var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{staticClass:"elevation-0 loan-list",attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.items,"expanded":_vm.expanded,"items-per-page":_vm.size,"page":_vm.page,"server-items-length":_vm.count,"footer-props":{'items-per-page-options': [10, 20, 50, 100, 500]},"show-expand":"","item-class":function (item) { return item.available_to_invest < 10 ? 'uninvestable' : ''; }},on:{"update:expanded":function($event){_vm.expanded=$event},"update:items-per-page":_vm.sizeChanged,"update:page":_vm.pageChanged,"item-expanded":_vm.expandChange},scopedSlots:_vm._u([{key:"header.amount",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"slot":"activator","dense":"","size":"12","color":"primary","dark":""},slot:"activator"},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('loanList.loanAmountDescription')))])])]}},{key:"header.available_to_invest",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"slot":"activator","dense":"","size":"12","color":"primary","dark":""},slot:"activator"},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('loanList.availableForInvestmentDescription')))])])]}},{key:"header.period",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"slot":"activator","dense":"","size":"12","color":"primary","dark":""},slot:"activator"},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('loanList.loanTermsDescription')))])])]}},{key:"header.investor_interest",fn:function(ref){
var header = ref.header;
return [_vm._v(" "+_vm._s(header.text)+" "),_c('v-tooltip',{attrs:{"bottom":"","max-width":"400"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"slot":"activator","dense":"","size":"12","color":"primary","dark":""},slot:"activator"},on),[_vm._v(" mdi-information-outline ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('loanList.investorInterestPercentDescription')))])])]}},{key:"item.country",fn:function(ref){
var item = ref.item;
return [_c('BaseCountry',{attrs:{"country":item.country}})]}},{key:"item.reference_number",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(item.reference_number))]),_c('BaseLoanOriginatorChip',{attrs:{"loan-originator":item.loan_originator}})]}},{key:"item.is_schedule_extended",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.is_schedule_extended? 'Yes': 'No')+" ")]}},{key:"item.period",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formattedPeriod(item.value_date, item.maturity_date) || _vm.$t('loanList.NA'))+" ("+_vm._s(_vm.formattedPeriod(new Date(), item.maturity_date))+") ")]}},{key:"item.buyback_guarantee",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{staticClass:"mb-1",attrs:{"title":item.buyback_guarantee ? _vm.$t('buyback.buybackGuaranteeYes') : _vm.$t('buyback.buybackGuaranteeNo')}},[_vm._v(_vm._s(item.buyback_guarantee ? 'mdi-shield' : 'mdi-shield-off'))])]}},{key:"item.amount",fn:function(ref){
var item = ref.item;
return [_c('BaseMoney',{attrs:{"amount":item.amount}})]}},{key:"item.available_to_invest",fn:function(ref){
var item = ref.item;
return [_c('BaseMoney',{attrs:{"amount":item.available_to_invest}})]}},{key:"item.publishing_date",fn:function(ref){
var item = ref.item;
return [_c('BaseDate',{attrs:{"date":item.publishing_date,"popup":"left"}})]}},{key:"item.value_date",fn:function(ref){
var item = ref.item;
return [_c('BaseDate',{attrs:{"date":item.value_date,"popup":"left"}})]}},{key:"item.investor_interest",fn:function(ref){
var item = ref.item;
return [_c('BasePercent',{attrs:{"amount":item.investor_interest}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(!_vm.showInvestButton(item))?_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-width":200,"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","dark":"","disabled":item.available_to_invest < 10}},'v-btn',attrs,false),on),[_vm._v(" "+_vm._s(_vm.$t('loanList.invest'))+" ")])]}}],null,true),model:{value:(_vm.menu[item.id]),callback:function ($$v) {_vm.$set(_vm.menu, item.id, $$v)},expression:"menu[item.id]"}},[_c('StartInvesting',{attrs:{"invest-target":{loan:item}},on:{"close":function($event){_vm.menu[item.id] = false},"success":_vm.investmentChanged}})],1):_vm._e(),_c('v-tooltip',{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-fab-transition',[(_vm.showInvestButton(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"success"}},'v-icon',attrs,false),on),[_vm._v(" mdi-briefcase-check ")]):_vm._e()],1)]}}],null,true)},[_c('span',{domProps:{"textContent":_vm._s(_vm.$t('loanList.youHaveAlreadyInvested'))}})])]}},{key:"expanded-item",fn:function(ref){
var expandedHeaders = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"px-0",attrs:{"colspan":expandedHeaders.length}},[_c('v-expand-transition',[_c('LoanDetail',{directives:[{name:"show",rawName:"v-show",value:(_vm.showing[item.id]),expression:"showing[item.id]"}],attrs:{"loan":item}})],1)],1)]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }